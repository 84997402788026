import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const loadData = createAsyncThunk("data/load", async () => {
  const response = await axios.get("https://", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return response.data;
});

const slice = createSlice({
  name: "data",
  initialState: {
    status: "idle",
    isLoaded: false,
    products: [],
    voucher: {
      title: "",
      discount: "",
    },
    openHeaderCart: false,
    openTopAlert: true,
    activeTab: 0,
    basket: [],
    leasingBasket: [],
    components: [],
    configurationFields: {},
    basketId:
      typeof window !== `undefined` ? localStorage.getItem("basketId") : "",
    leasingBasketId:
      typeof window !== `undefined`
        ? localStorage.getItem("leasingBasketId")
        : "",
  },
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setBasket: (state, action) => {
      state.basket = action.payload;
    },
    setOpenHeaderCart: (state, action) => {
      state.openHeaderCart = action.payload;
    },
    setOpenTopAlert: (state, action) => {
      state.openTopAlert = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setBasketId: (state, action) => {
      localStorage.setItem("basketId", action.payload);
      state.basketId = localStorage.getItem("basketId");
    },
    setLeasingBasketId: (state, action) => {
      localStorage.setItem("leasingBasketId", action.payload);
      state.leasingBasketId = localStorage.getItem("leasingBasketId");
    },
    removeBasket: (state, action) => {
      localStorage.removeItem(action.payload);
      if (action.payload === "basketId") {
        state.basketId = null;
        state.basket = [];
      }
      if (action.payload === "leasingBasketId") {
        state.leasingBasketId = null;
        state.leasingBasket = [];
      }
    },
    setComponents: (state, action) => {
      state.components = action.payload;
    },
    resetComponents: (state) => {
      state.components = [];
    },
   setConfigurationFields: (state, action) => {
      state.configurationFields = action.payload;
    },
    resetConfigurationFields: (state) => {
      state.configurationFields = [];
    },
    addToBasket: (state, action) => {
      state.basket = action.payload;
    },
    addToLeasingBasket: (state, action) => {
      state.leasingBasket = action.payload;
    },
    setVoucher: (state, action) => {
      state.voucher = action.payload;
    },
  },
  extraReducers: {
    [loadData.pending]: (state) => {
      state.status = "loading";
    },
    [loadData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.isLoaded = true;
    },
    [loadData.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default slice.reducer;

// Actions

export const {
  setTab,
  setBasket,
  addToBasket,
  addToLeasingBasket,
  setBasketId,
  setLeasingBasketId,
  setVoucher,
  setOpenHeaderCart,
  setOpenTopAlert,
  setActiveTab,
  removeBasket,
  setComponents,
  resetComponents,
  setConfigurationFields,
  resetConfigurationFields,
} = slice.actions;
