import { createSlice } from "@reduxjs/toolkit";
import differenceInMinutes from "date-fns/differenceInMinutes";

// Create persistent login for a max of 30 mins
if (typeof window !== `undefined`) {
  let timestamp = window.localStorage.getItem("timestamp")
    ? differenceInMinutes(
        new Date(),
        new Date(window.localStorage.getItem("timestamp"))
      )
    : 0;
  if (timestamp >= 180) {
    window.localStorage.clear();
  }
}

const guest = {
  firstName: "Gast",
  deliveryAddresses: [],
  invoiceAddress: {
    country: {},
  },
};

const slice = createSlice({
  name: "user",
  initialState: {
    user:
      typeof window !== `undefined`
        ? {
            deliveryAddresses: [],
            invoiceAddress: {
              country: {},
            },
          }
        : guest,
    token:
      typeof window !== `undefined`
        ? localStorage.getItem("zepshop2021")
        : null,
    error: null,
    isNotLoggedIn: null,
    chat: null,
    chatClient: null,
    status: "idle",
  },
  reducers: {
    logout: (state) => {
      localStorage.removeItem("user");
      localStorage.removeItem("zepshop_timestamp");
      localStorage.removeItem("zepshop2021");
      localStorage.removeItem("basketId");
      localStorage.removeItem("leasingBasketId");
      localStorage.removeItem("zepCart");

      state.user = guest;
      state.token = null;
      state.error = null;
      state.isNotLoggedIn = null;
      state.chat = null;
      state.chatClient = null;
      state.status = "idle";
    },
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("zepshop2021", action.payload);
      localStorage.setItem("zepshop_timestamp", new Date());
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: {},
});

export default slice.reducer;

// Actions

export const { logout, setUser, setToken } = slice.actions;
