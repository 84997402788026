import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import fetch from "isomorphic-fetch";
import { navigate } from "@reach/router";

const httpLink = createHttpLink({
  uri: process.env.GATSBY_BACKEND_ADDRESS,
});

const resetToken = onError(({ response, networkError }, cb) => {
  // Here, we fetch the error that users token is invalid. We have to clear the localstorage and reset all the App state
  if (
    response.errors[0].message === "The token is invalid" ||
    response.errors[0].message === "Unable to parse token"
  ) {
    localStorage.clear();
    navigate("/").then(() => {
      window.location.reload();
    });
  }
});

const withToken = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("zepshop2021");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const authFlowLink = withToken.concat(resetToken);

const link = authFlowLink.concat(httpLink);

export const client = new ApolloClient({
  fetch,
  link: link,
  credentials: "include",
  cache: new InMemoryCache(),
});
