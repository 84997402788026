module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5JZJ9FJ","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Zeppelin Shop","short_name":"Zeppelin Shop","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/images/favicon.png","icons":[{"src":"/zeppelin-cat-favicon/apple-touch-icon.png","sizes":"152x152","type":"image/png"},{"src":"/zeppelin-cat-favicon/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/zeppelin-cat-favicon/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/zeppelin-cat-favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/zeppelin-cat-favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"afbba972dbc017069e4c53b8bffb41bb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.zeppelin-cat.de/shop"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
