import { createTheme } from "@mui/material/styles";
import guidelines from "zeppelin-element-library/guidelines.json";
import "zeppelin-element-library/bundle/zel.css";

const primary = guidelines.theme.yellow.primary;
const secondary = guidelines.theme.yellow.secondary;

const typography = [];
let headings = Object.keys(guidelines.typography.headings);
headings.forEach((elem) => {
  let variants = Object.keys(guidelines.typography.headings[elem]);
  variants.forEach((item) => {
    let temp = {
      props: { variant: item },

      style: {
        fontFamily: guidelines.typography.headings[elem][item]["font-family"],
        fontSize: guidelines.typography.headings[elem][item]["font-size"],
        fontWeight: guidelines.typography.headings[elem][item]["font-weight"],
        letterSpacing:
          guidelines.typography.headings[elem][item]["letter-spacing"],
        lineHeight: guidelines.typography.headings[elem][item]["line-height"],
        textTransform:
          guidelines.typography.headings[elem][item]["text-transform"],
      },
    };
    typography.push(temp);
  });
});
Object.keys(guidelines.typography.body).forEach((item) => {
  let temp = {
    props: { variant: item },
    style: {
      fontFamily: guidelines.typography.body[item]["font-family"],
      fontSize: guidelines.typography.body[item]["font-size"],
      fontWeight: guidelines.typography.body[item]["font-weight"],
      letterSpacing: guidelines.typography.body[item]["letter-spacing"],
      lineHeight: guidelines.typography.body[item]["line-height"],
      textTransform: guidelines.typography.body[item]["text-transform"],
    },
  };
  typography.push(temp);
});

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: guidelines.color.support.alert,
    },
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    lineHeight: "1.75rem",
    fontDisplay: "swap",
  },
  logo: guidelines.logo,
  indigo: guidelines.theme.indigo,
  yellow: guidelines.theme.yellow,
  red: guidelines.theme.red,
  blue: guidelines.theme.blue,
  color: guidelines.color,
  other: {
    red: "#ff6562",
    darkRed: "#e00006",
    yellow: "#FFD500",
    blue: "#006DFF",
    blueGray: "#5a768e",
    green: "#35BC00",
    gray: {
      100: "#fafafa",
      200: "#f1f1f1",
      300: "#f8f8f8",
      400: "#ebebeb",
      500: "#bdbdbd",
      600: "#3E3E3E",
    },
  },
  space: guidelines.spacing.component,
  components: {
    MuiTypography: {
      variants: typography,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    //   variants: [
    //     {
    //       props: { variant: "primary", color: "primary", size: "large" },
    //       style: {
    //         padding: "1.25rem 2rem",
    //         background: primary,
    //         color: guidelines.color.font,
    //         "&:hover": {
    //           background: secondary,
    //         },
    //         "&:disabled": {
    //           background: guidelines.color.black[40],
    //         },
    //       },
    //     },
    //     {
    //       props: { variant: "primary", color: "secondary", size: "large" },
    //       style: {
    //         padding: "1.25rem 2rem",
    //         background: secondary,
    //         color: guidelines.color.font,
    //         "&:hover": {
    //           background: primary,
    //         },
    //         "&:disabled": {
    //           background: guidelines.color.black[40],
    //         },
    //       },
    //     },
    //     {
    //       props: { variant: "primary", color: "primary", size: "medium" },
    //       style: {
    //         padding: ".875rem 1.5rem",
    //         background: primary,
    //         color: guidelines.color.font,
    //         "&:hover": {
    //           background: secondary,
    //         },
    //         "&:disabled": {
    //           background: guidelines.color.black[40],
    //         },
    //       },
    //     },
    //     {
    //       props: { variant: "primary", color: "secondary", size: "medium" },
    //       style: {
    //         padding: ".875rem 1.5rem",
    //         background: secondary,
    //         color: guidelines.color.font,
    //         "&:hover": {
    //           background: primary,
    //         },
    //         "&:disabled": {
    //           background: guidelines.color.black[40],
    //         },
    //       },
    //     },
    //     {
    //       props: { variant: "primary", color: "primary", size: "small" },
    //       style: {
    //         padding: ".375rem 1rem",
    //         textTransform: "none",
    //         background: primary,
    //         color: guidelines.color.font,
    //         "&:hover": {
    //           background: secondary,
    //         },
    //         "&:disabled": {
    //           background: guidelines.color.black[40],
    //         },
    //       },
    //     },
    //     {
    //       props: { variant: "primary", color: "secondary", size: "small" },
    //       style: {
    //         padding: ".375rem 1rem",
    //         textTransform: "none",
    //         background: secondary,
    //         color: guidelines.color.font,
    //         "&:hover": {
    //           background: primary,
    //         },
    //         "&:disabled": {
    //           background: guidelines.color.black[40],
    //         },
    //       },
    //     },
    //     {
    //       props: { variant: "secondary", color: "primary", size: "large" },
    //       style: {
    //         padding: "1.25rem 2rem",
    //         color: primary,
    //         border: `1px solid ${primary}`,
    //         "&:hover": {
    //           color: secondary,
    //           border: `1px solid ${secondary}`,
    //         },
    //         "&:disabled": {
    //           background: guidelines.color.black[40],
    //         },
    //       },
    //     },
    //     {
    //       props: { variant: "secondary", color: "secondary", size: "large" },
    //       style: {
    //         padding: "1.25rem 2rem",
    //         color: secondary,
    //         border: `1px solid ${secondary}`,
    //         "&:hover": {
    //           color: primary,
    //           border: `1px solid ${primary}`,
    //         },
    //         "&:disabled": {
    //           background: guidelines.color.black[40],
    //         },
    //       },
    //     },
    //     {
    //       props: { variant: "secondary", color: "primary", size: "medium" },
    //       style: {
    //         padding: ".875rem 1.5rem",
    //         color: primary,
    //         border: `1px solid ${primary}`,
    //         "&:hover": {
    //           color: secondary,
    //           border: `1px solid ${secondary}`,
    //         },
    //         "&:disabled": {
    //           background: guidelines.color.black[40],
    //         },
    //       },
    //     },
    //     {
    //       props: { variant: "secondary", color: "secondary", size: "medium" },
    //       style: {
    //         padding: ".875rem 1.5rem",
    //         color: secondary,
    //         border: `1px solid ${secondary}`,
    //         "&:hover": {
    //           color: primary,
    //           border: `1px solid ${primary}`,
    //         },
    //         "&:disabled": {
    //           background: guidelines.color.black[40],
    //         },
    //       },
    //     },
    //     {
    //       props: { variant: "secondary", color: "primary", size: "small" },
    //       style: {
    //         padding: ".375rem 1rem",
    //         textTransform: "none",
    //         color: primary,
    //         border: `1px solid ${primary}`,
    //         "&:hover": {
    //           color: secondary,
    //           border: `1px solid ${secondary}`,
    //         },
    //         "&:disabled": {
    //           background: guidelines.color.black[40],
    //         },
    //       },
    //     },
    //     {
    //       props: { variant: "secondary", color: "secondary", size: "small" },
    //       style: {
    //         padding: ".375rem 1rem",
    //         textTransform: "none",
    //         color: secondary,
    //         border: `1px solid ${secondary}`,
    //         "&:hover": {
    //           color: primary,
    //           border: `1px solid ${primary}`,
    //         },
    //         "&:disabled": {
    //           background: guidelines.color.black[40],
    //         },
    //       },
    //     },
    //   ],
    //   styleOverrides: {
    //     root: {
    //       fontWeight: 500,
    //       fontSize: "1rem",
    //       letterSpacing: "0.078125rem",
    //       lineHeight: "1.25em",
    //     },
    //   },
    // },
    // Input
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       borderRadius: 0,
    //     },
    //     input: {
    //       fontSize: "1rem",
    //       lineHeight: "1.75rem",
    //       padding: "1rem 1.25rem",
    //     },
    //   },
    // },
    // MuiInputLabel: {
    //   styleOverrides: {
    //     root: {
    //       lineHeight: "1.25rem",
    //     },
    //   },
    // },
    // MuiFormHelperText: {
    //   styleOverrides: {
    //     root: {
    //       lineHeight: "1.25rem",
    //       marginLeft: 0,
    //     },
    //   },
    // },
    // Select
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: "8.5px 14px",
        },
      },
    },
    // Radio
    MuiRadio: {
      styleOverrides: {
        root: {
          color: guidelines.color.font,
          "$icon &": {
            outlineOffset: 1,
          },
        },
        colorSecondary: {
          "&$checked": {
            backgroundImage: "linear-gradient(180deg,green,hsla(0,0%,100%,0))",
            outline: "1px auto rgba(19,124,189,.6)",
            color: primary,
          },
          "$icon &": {
            outline: "1px auto rgba(19,124,189,.6)",
            outlineOffset: 1,
          },
        },
      },
    },
    // Progress
    // MuiCircularProgress: {
    //   styleOverrides: {
    //     svg: {
    //       height: "1.5rem",
    //       width: "1.5rem",
    //       animation: "#{$prefix}rotate 0.9s linear infinite",
    //     },
    //     circle: {
    //       cy: 12,
    //       stroke: "black",
    //       strokeOpacity: 0.6,
    //       strokeWidth: 1.25,
    //       fill: "transparent",
    //       strokeLinecap: "round",
    //       strokeDasharray: "12, 12",
    //       strokeDashoffset: 11.05,
    //       animation: "#{$prefix}inertia 1.3s ease-in-out infinite",
    //       transformOrigin: "50% 50%",
    //       transform: "scale(1,5)",
    //     },
    //   },
    // },
  },
});

export default theme;
